import React from 'react';
import SimpleSelect from '../Form/Select/SimpleSelect';
import { useFormikContext } from 'formik';
import { CustomerFormValues } from './CreateCustomerForm';

const CUSTOMER_SOURCES = [
  'Email from retailer',
  'Email from a league or team',
  'Email or push notification from SidelineSwap',
  'At the event',
  'At the store prior to the event',
  'From a friend',
  'Social Media',
  'SportsEngine',
  'Nextdoor',
  'GameChanger',
  'Other',
];

interface Props {}

const SourceField: React.FC<Props> = () => {
  const formik = useFormikContext<CustomerFormValues>();

  return (
    <SimpleSelect
      items={CUSTOMER_SOURCES}
      onChange={i => formik.setFieldValue('source', i)}
      value={formik.values.source}
      renderItem={String}
      label="Source"
      itemToString={String}
      placeholder="Where did this customer hear about us?"
    />
  );
};

export default SourceField;
